import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { allProduct } from "../../../../common/components/product/allProduct";
import "../components/storeItem/storeItem.css";
import StoreProducts from "../components/storeItem/StoreProducts";
import { StoreItemImage } from "./StoreItemImage";
import { ProductDetails } from "./ProductDetail";
import useFetch from "../components/StoreApi/useFetch";
import { handleError } from "../../../../utils/toast";
import { BASE_URL } from "../../../../utils/services";

const Product = () => {
  const { id } = useParams();
  const [quantity, setQuantity] = useState(0);
  const [image, setImage] = useState(0);
  const [cart, setCart] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState([]);
  const { loading, data, error } = useFetch(`${BASE_URL}/product/${id}`);
  const item = useMemo(() => [data?.product][0] || null, [image, data]);

  useEffect(() => {
    const updatedCart = totalQuantity.filter((val) => val.quantity > 0);
    setCart(updatedCart);
    localStorage.setItem("user-Item", JSON.stringify(updatedCart));
  }, [image, totalQuantity]);

  const handleChange = (newQuantity) => {
    if (newQuantity >= 0) {
      const { id, colors, name, description, discounted_amount, images } =
        item[0] ?? null;
      const color = colors[image] ?? null;
      const productImage = images[image] ?? null;
      const updatedData = {
        id,
        color,
        name,
        description,
        price: discounted_amount,
        productImage,
        quantity: quantity + 1,
      };

      setQuantity(Number(newQuantity));

      setTotalQuantity([updatedData]);
    }
  };

  if (error) {
    handleError(error);
  }

  return (
    <Fragment>
      {loading && (
        <div className="flex justify-center items-center w-full">
          <div className="form-loading" />
        </div>
      )}
      <div className="storeItem-page__inner !mt-16 !text-[#D7D7D7]">
        {data && !loading && (
          <>
            <StoreItemImage
              color={item[0].colors}
              item={item[0]}
              image={image}
              setImage={setImage}
            />
            <ProductDetails
              item={item[0]}
              quantity={quantity}
              onQuantityChange={handleChange}
            />
          </>
        )}
        {/* <h1 className="text-4xl text-white">Yoooo</h1> */}
      </div>
      <div className="flex flex-col mt-12 lg:!px-20">
        <h2 className="w-full text-center md:my-[40px] my-3 font-light md:text-4xl text-2xl text-white">
          You May Also Like
        </h2>
        <StoreProducts />
      </div>
    </Fragment>
  );
};

export default Product;
