import React from "react";
import PropTypes from "prop-types";

const NoProducts = () => {
  return (
    <div className="text-center w-full flex justify-center">
      <p>No products available in &ldquo;STORE&rdquo; or failed to load.</p>
    </div>
  );
};

NoProducts.propTypes = {};

export default NoProducts;
