import React from 'react'
import PropTypes from 'prop-types'

function ProductName({ name }) {
  return (
    <span className="block text-xl max-md:text-lg my-3 font-extralight tracking-tight text-[#D7D7D7]">
      {name}
    </span>
  );
}

ProductName.propTypes = {
    name: PropTypes.string
}

export default ProductName
