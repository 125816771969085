import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import axaoIcon from "../../assets/icons/axaoIcon.svg";
import profileIcon from "../../assets/icons/profileIcon.svg";
import searchIcon from "../../assets/icons/searchIcon.svg";
import cartIcon from "../../assets/icons/cartIcon3.svg";
import cart from "../../assets/icons/cartIcon2.svg";
import moreIcon from "../../assets/icons/moreIcon.svg";
import dropdown from "../../assets/icons/regular/downCaret.svg";
import darkCartIcon from "../../assets/icons/darkCartIcon.svg";
import { LandingPagePadding } from "../../common/styles/Paddings";
import { useItemContext } from "../../screens/landingpage/productStore/components/storeItem/StoreContext";
import NavDropdown from "./dropdown";
import "./dropdown/dropdown.css";
import MobileSidebar from "./mobileSidebar";

const Header = ({ setSidebarOpen }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { cartQuantity, isItemAdded } = useItemContext();
  const [banner, setBanner] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const country = localStorage.getItem("country");

  const handleRouteHomeLogo = () => {
    if (openSidebar) {
      setOpenSidebar(false);
    }
  };

  useEffect(() => {
    setCloseDropdown(false);
  }, [closeDropdown]);

  useEffect(() => {
    setTimeout(() => setBanner(true), 5000);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollHeight(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {
        setBanner(false);
      } else if (window.scrollY < 200) {
        setBanner(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollHeight]);

  useEffect(() => {
    if (openSidebar) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [openSidebar]);

  return (
    <Container className="bg-dark flex align-center header-container justify-between relative">
      {pathname.includes("account-details") && (
        <div className="hidden lg:block absolute top-0 right-0 payment__header h-full bg-[#151515]" />
      )}
      {country === "Nigeria" && pathname !== "/products/store" && (
        <div
          className={`w-full transition-all duration-700 bg-[#fff] h-[25px] text-center ${
            banner
              ? "opacity-1 -translate-y-[100%]"
              : "opacity-0 -translate-y-[89px]"
          } absolute left-0 right-0`}
        >
          <small className="text-xs text-black font-extralight tracking-tight">
            Preorder the Onyx Panther 2{" "}
            <Link
              to="/products/store/Onyx_Panther_2"
              className="underline text-[#0F71E2]"
            >
              Store
            </Link>
          </small>
        </div>
      )}
      {country === "Nigeria" && pathname === "/products/store" && (
        <div
          className={`w-full transition-all duration-700 bg-[#fff] h-[25px] text-center absolute left-0 right-0 opacity-1 -translate-y-[100%]`}
        >
          <small className="text-xs text-black font-extralight tracking-tight">
            Preorder the Onyx Panther 2{" "}
            <Link
              to="/products/store/Onyx_Panther_2"
              className="underline text-[#0F71E2]"
            >
              Store
            </Link>
          </small>
        </div>
      )}
      <div
        className={`landingpage_header--left !transition-all flex align-center ${
          banner || pathname === "/products/store" ? "!mt-6" : null
        }`}
      >
        <a href="/" onClick={handleRouteHomeLogo}>
          <img
            className="logo cursor-pointer"
            src={axaoIcon}
            alt="AXOA - Logo"
          />
        </a>
        <nav className="landingpage_header--left-links">
          <div
            className={`product-tab product-tab-dropdown ${
              closeDropdown && "close-dropdown"
            }`}
          >
            <div className="color-text product-text flex items-center cursor-pointer">
              Products
              <img src={dropdown} className="product-dropdown" alt="" />
            </div>
            <NavDropdown
              closeDropdown={closeDropdown}
              setCloseDropdown={setCloseDropdown}
            />
          </div>

          <NavLink className="color-text cursor-pointer" to="/.">
            Technology
          </NavLink>

          <NavLink className="color-text cursor-pointer" to="products/store">
            Our Store
          </NavLink>
          <NavLink className="color-text cursor-pointer" to="/.">
            AXAO for Business
          </NavLink>
        </nav>
      </div>
      <div
        className={`landingpage_header--right transition-all ${
          banner || pathname === "/products/store" ? "!mt-6" : null
        }`}
      >
        <div
          // onClick={() => navigate("/activateaccount/profilepicture")}
          className="landingpage_header--right-icon"
        >
          <img src={profileIcon} alt="" />
        </div>

        <div className="landingpage_header--right-icon">
          <img src={searchIcon} alt="" />
        </div>

        <button
          className={`landingpage_header--right-icon cart-icon__container ${
            cartQuantity <= 0 && !isItemAdded ? "cursor-not-allowed" : ""
          }`}
          disabled={cartQuantity <= 0 && !isItemAdded}
          onClick={() =>
            navigate(cartQuantity > 0 && isItemAdded ? "/products/cart" : "#")
          }
        >
          <img
            src={cartQuantity > 0 && isItemAdded ? cart : cartIcon}
            alt=""
            className="!w-[60px]"
          />
          {cartQuantity > 0 && isItemAdded ? (
            <span className="cart-badge !bg-transparent !text-xs !-translate-x-6 !translate-y-3.5 ">
              {cartQuantity}
            </span>
          ) : (
            ""
          )}
        </button>
      </div>

      {/* Mobilesidebar */}
      <header
        className={`md:hidden ${
          banner || pathname === "/products/store" ? "!mt-6" : null
        }`}
      >
        <MobileSidebar
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
        />
      </header>

      {/* <div className="landingpage_header--right-mobile">
        <div
          // onClick={() => setSidebarOpen(true)}
          className="landingpage_header--right-mobile-icon"
        >
          <img src={moreIcon} alt="" />
        </div>

        <div className="landingpage_header--right-mobile-icon">
          <img src={darkCartIcon} alt="" />
        </div>
      </div> */}
    </Container>
  );
};

Header.propTypes = {
  setSidebarOpen: PropTypes.func,
};

const Container = styled.div`
  width: 100%;
  ${LandingPagePadding};
  height: 89px;
  overflow-x: visible;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;

  .logo {
    position: relative;
    z-index: 999;
  }
  .cart-icon__container {
    position: relative;

    .cart-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -11px;
      right: -5px;
      font-family: inherit;
      font-weight: 500;
      width: 16px;
      height: 16px;
      padding: 1px;
      background-color: #f9a000;
      border-radius: 50%;
      font-size: 11px;
      color: #ffffff;
    }
  }
  .landingpage_header- {
    &-left {
      &-links {
        display: none;
        justify-content: center;
        align-items: center;

        a {
          font-size: 13px;
          font-weight: 300;
        }

        .product-text {
          font-size: 13px;
          font-weight: 300;
          display: inline-block;
        }

        .product-dropdown {
          margin-left: 5px;
          width: 8px;
          height: 8px;
        }
      }
    }
    &-right {
      display: none;
      z-index: 99999;

      &-icon {
        &:not(:last-child) {
          margin-right: 30px;
        }
        img {
          width: 17.98px;
          height: 17.98px;
        }

        cursor: pointer;
      }
    }
    &-right-mobile {
      display: flex;
      > * {
        margin-right: 10px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .landingpage_header- {
      &-left {
        &-links {
          display: flex;
          margin-left: 62.79px;
          > * {
            margin-right: 36px;
          }
          .active,
          .product-text {
            color: #ffffff;
            opacity: 1;
          }
        }
      }

      &-right {
        display: flex;
      }
      &-right-mobile {
        display: none;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .landingpage_header- {
      &-left {
        &-links {
          margin-left: 40px;

          a,
          .product-text {
            font-size: 13px;
          }
          > * {
            margin-right: 17px;
          }
        }
      }
    }
  }
`;

export default Header;
