import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../../../utils/services";

export function useLikedProductHandler(product) {
  const [isLiked, setIsLiked] = useState([]);

  useEffect(() => {
    if (product?.id) {
      const existingLikedProducts = JSON.parse(localStorage.getItem("likedProducts")) || [];

      // Check if the product already exists in the likedProducts list
      const productIndex = existingLikedProducts.findIndex(
        (p) => p.product_id === product.id
      );

      if (productIndex === -1) {
        // If the product isn't in the likedProducts list, add it
        const newLikedProduct = {
          liked: product.likes || 0, // Initialize from the product's "likes" property
          status: false,
          product_id: product.id,
        };
        const updatedLikedProducts = [...existingLikedProducts, newLikedProduct];
        localStorage.setItem("likedProducts", JSON.stringify(updatedLikedProducts));
        setIsLiked(updatedLikedProducts);
      } else {
        // Use the existing data from localStorage
        setIsLiked(existingLikedProducts);
      }
    } else {
      // Reset state if no product is passed
      setIsLiked([]);
    }
  }, [product]);

  return { isLiked, setIsLiked };
}

export async function toggleLike(setIsLiked, product_id) {
  try {
    setIsLiked((prev) => {
      const updatedLikedProducts = prev.map((item) =>
        item.product_id === product_id
          ? {
              ...item,
              status: !item.status,
              liked: item.liked === 0 ? 1 : 0,
            }
          : item
      );
      localStorage.setItem("likedProducts", JSON.stringify(updatedLikedProducts));
      return updatedLikedProducts;
    });

    const likedProduct = JSON.parse(localStorage.getItem("likedProducts")).find(
      (item) => item.product_id === product_id
    );

    if (likedProduct.liked === 1) {
      // Unlike the product
      const res = await axios.get(`${BASE_URL}/productunlike/${product_id}`);
      if (res.status === 200) {
        console.log("Unliked a product");
      } else {
        console.log("Failed to unlike the product:", res.data);
        handleError("Failed to Unlike this product");
      }
    } else {
      // Like the product
      const res = await axios.get(`${BASE_URL}/productlike/${product_id}`);
      if (res.status === 200) {
        console.log("Liked a product");
      } else {
        console.log("Failed to like the product:", res.data);
        handleError("Failed to like this product");
      }
    }
  } catch (error) {
    console.error("Error toggling like:", error);
  }
}

useLikedProductHandler.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    likes: PropTypes.number,
    price: PropTypes.string,
    images: PropTypes.array,
    category: PropTypes.string,
    status: PropTypes.string,
    stock_quantity: PropTypes.number,
  }),
};

toggleLike.propTypes = {
  setIsLiked: PropTypes.func,
  product_id: PropTypes.string,
};
