import React from "react";
import PropTypes from "prop-types";

function StatusBadge({ label }) {
  return (
    <div className="rounded-[8px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 border border-solid border-white/5 z-10">
      {label}
    </div>
  );
}

StatusBadge.propTypes = {
  label: PropTypes.string,
};

export default StatusBadge;
