import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const ScrollButton = forwardRef(({ children, dir, ...rest }, ref) => {
  const { pathname } = useLocation();
  // console.log(ref)
  const scroll = (direction) => {
    const { current } = ref;
    if (current) {
      const scrollAmount = 50;
      const scrollStep = direction === "left" ? -5 : 5; // Increment for each step
      const targetScrollLeft = current.scrollLeft + (direction === "left" ? -scrollAmount : scrollAmount);
  
      const smoothScroll = () => {
        if (
          (scrollStep < 0 && current.scrollLeft > targetScrollLeft) || 
          (scrollStep > 0 && current.scrollLeft < targetScrollLeft)
        ) {
          current.scrollLeft += scrollStep;
          requestAnimationFrame(smoothScroll);
        }
      };
  
      requestAnimationFrame(smoothScroll);
    }
  };
  
  

  return (
    <>
      {pathname !== "/products/store" && (
        <button
          ref={ref}
          {...rest}
          onClick={() => scroll(dir)}
          className={`absolute store__btn shadow-sm ${
            dir === "left" ? "left-0" : "right-0"
          } top-1/3 transform -translate-y-1/2 z-10 h-4/5 w-12 rounded-l-[20px] bg-black/0 hover:bg-black/5 focus:outline-none flex justify-center items-center`}
        >
          {children}
        </button>
      )}
    </>
  );
});

ScrollButton.displayName = "button";
ScrollButton.propTypes = {
  children: PropTypes.any,
  ref: PropTypes.any,
  rest: PropTypes.any,
  dir: PropTypes.string,
};

export default ScrollButton;
