import { useMutation, useQuery } from "react-query";
import { fetchApi, postApi } from "./services";
import { handleError } from "./toast";

export const usePostApi = (url) => {
  return useMutation({
    mutationFn: (body) => postApi(url, body), // Accepts `body` directly here
    onSettled: (_, error) => {
      if (error) {
        // Corrected instance check
        handleError(error.message);
      }
    },
  });
};

export const useFetchApi = (url, page = "", itemsPerPage = "", query = "") => {
  const link = !page ? url : `${url}?limit=${page}&offset=${itemsPerPage}`;
  const newLink = query.includes("category") ? query : link;
  return useQuery({
    queryKey: [query, url, page ?? ""],
    queryFn: () => fetchApi(newLink),
    enabled: !!url,
  });
};
