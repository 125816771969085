import React from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import Router from "./router/Router";
import StateProvider from "./libs/context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Retries failed queries up to 5 times
      retry: 5,
      refetchOnWindowFocus:false,
      staleTime:300
    },
    mutations: {
      retry: 5,  // Retries failed mutations up to 5 times
    },
  },
  queryCache: new QueryCache(),
});

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <StateProvider>
        <Router />
      </StateProvider>
      <Toaster />
    </QueryClientProvider>
  );
};

export default App;
