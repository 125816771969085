import React from "react";
import PropTypes from "prop-types";

const Loading = () => {
  return (
    <div className="flex justify-center items-center w-full py-3">
      <div className="form-loading" />
    </div>
  );
};

Loading.propTypes = {};

export default Loading;
