import React from "react";
import PropTypes from "prop-types";
import { Slider } from "@mui/material";

function PriceSlider({ prices, min, max }) {
  const filteredProducts = prices.map((price) =>
    Number((price || "").replace(/[^0-9.-]+/g, ""))
  );
  const minPrice = Math.min(...filteredProducts);
  const maxPrice = Math.max(...filteredProducts);

  function valuetext(value) {
    return `${value}`;
  }
  const minDistance = 1;

  const [value2, setValue2] = React.useState([minPrice, maxPrice]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue);
    }
  };

  
  return (
    <>
      <Slider
        min={minPrice}
        max={maxPrice}
        getAriaLabel={() => "Minimum distance shift"}
        value={value2}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        className="slider"
        disableSwap
      />
      <button
        className="bg-blue-500/50 px-4 py-2 text-white rounded-md text-sm"
        onClick={() => {
          min(value2[0]);
          max(value2[1]);
        }}
      >
        Apply
      </button>
    </>
  );
}

PriceSlider.propTypes = {
  prices: PropTypes.array,
  max: PropTypes.func,
  min: PropTypes.func,
};

export default React.memo(PriceSlider);
